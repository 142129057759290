;(function($) {

	var isMobile = function() {
		return ($('html').hasClass('app_touchevents')) ? true : false;
	}

	var testimonialsSlider = function() {
		return $('.js-testimonials').slick({
			autoplay: true,
			autoplaySpeed: 7000,
			speed: 1000,
			fade: true,
			dots: true,
			arrows: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			pauseOnDotsHover: false,
		});
	}

	var heroSlider = function() {
		return $('.js-backgrounds').slick({
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 1000,
			fade: true,
			dots: true,
			arrows: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			pauseOnDotsHover: false,
		});
	}

	var imagePopups = function() {
		$('.rgg-imagegrid').magnificPopup({
			mainClass: 'mfp-fade',
			delegate: 'a', 
			type: 'image',
			titleSrc: 'title',
			gallery: {
				enabled: true
			}
		});
	}

	$(document).ready(function() {
		testimonialsSlider();
		heroSlider();
		imagePopups();
	});

})(jQuery);